import React, {useState} from "react"
import { Link } from "gatsby"
import '../styles/index.scss'
import {Container, Row} from "reactstrap"

import MainLogo from "../../content/assets/svgs/greenLogo.inline.svg";
import Wordmark from "../../content/assets/svgs/EMEM-text-only-white.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const Layout = ({ location, title, children }) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const menuClass = (menuOpen) ? 'open' : 'closed';

  return (

    <>
      <div className="main_header">
        <Container>
          <Row>
            <header>
              <Link to="/"><MainLogo className="main-icon" /></Link> <Link to="/" className="d-inline-block d-md-none d-lg-inline-block wordmark"><Wordmark className="main-wordmark" /></Link>
              <nav className={`d-inline-block ${menuClass}`}>
                <FontAwesomeIcon className="d-block d-md-none closeMobileMenu" icon={faTimes} onClick={(e) => setMenuOpen(false)} />
                <ul className="list-unstyled d-flex flex-md-row flex-column mb-0">

                  <li className="list-inline-item mb-0">
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li className="list-inline-item mb-0 coming-soon d-none d-md-inline-block">
                    <span className="dim">Themes &amp; Layouts</span>
                    <span className="patrick">coming soon</span>
                  </li>
                  <li className="list-inline-item mb-0">
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li className="list-inline-item mb-0">
                    <Link to="/services">Services</Link>
                  </li>
                </ul>
              </nav>
              <FontAwesomeIcon className="d-block d-md-none hamburger" icon={faBars} onClick={(e) => setMenuOpen(true)}  />
            </header>
          </Row>
        </Container>
      </div>

      <main>{children}</main>

      
      <footer id="main_footer" className="py-5">
        <Container>
          <Row>
          <Link to="/"><MainLogo className="main-icon" /></Link> <Link to="/" className="wordmark"><Wordmark className="main-wordmark" /></Link>
          </Row>
        </Container>
      </footer>

    </>


  )
}

export default Layout
